.transparent-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: 0 !important;
  width: auto;
  height: auto;

  &:disabled {
    cursor: default;
  }
}

html, body {
  font-family: 'Roboto', sans-serif !important;
  overflow-x: hidden;
}

.ant-collapse-content {
  border-top: none;
}